<template>
  <el-table :data="tags">
    <el-table-column :label="$t('tag.name.en')" prop="name.en" />
    <el-table-column :label="$t('tag.name.tw')" prop="name.tw" />
    <el-table-column :label="$t('tag.name.cn')" prop="name.cn" />
    <el-table-column :label="$t('column.action')">
      <template slot-scope="scope">
        <router-link
          v-if="enableToAddUser(scope.row.type)"
          :to="
            $route.query.tagType
              ? {
                  name: 'tag',
                  params: { tagId: scope.row.id },
                  query: { tagType: $route.query.tagType }
                }
              : {
                  name: 'tag',
                  params: { tagId: scope.row.id }
                }
          "
        >
          <i class="fas fa-user-plus action-icon" />
        </router-link>
        <router-link
          :to="{
            name: 'editTag',
            params: { tagId: scope.row.id }
          }"
        >
          <i class="fas fa-edit action-icon" />
        </router-link>
        <span @click="$emit('deleteTag', scope.row.id)">
          <i class="fas fa-trash warning-icon" />
        </span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    enableToAddUser(tagType) {
      const userTags = ["user", "student", "teacher", "parent"];
      return userTags.includes(tagType);
    }
  }
};
</script>
