<template>
  <div class="body container">
    <Breadcrumb>
      <template v-if="$route.query.tagType" slot="lastPage">
        {{ thePageTitle }}
      </template>
    </Breadcrumb>
    <div class="shortcut">
      <el-radio-group :value="$route.query.tagType || 'all'">
        <el-radio-button label="all">
          <router-link class="tagLink" :to="{ name: 'tags' }">All</router-link>
        </el-radio-button>
        <el-radio-button
          :key="type"
          :label="type"
          v-for="type in Object.keys(tagTypeDict)"
        >
          <router-link
            class="tagLink"
            :to="{ name: 'tags', query: { tagType: type } }"
          >
            {{ $t(`pageTitle.${tagTypeDict[type]}`) }}
          </router-link>
        </el-radio-button>
      </el-radio-group>
    </div>
    <h1>
      {{ thePageTitle }}
      <router-link
        :to="
          $route.query.tagType
            ? { name: 'createTag', query: { tagType: $route.query.tagType } }
            : { name: 'createTag' }
        "
      >
        <i class="fas fa-plus" />
      </router-link>
    </h1>
    <Tags :tags="tags" @deleteTag="deleteTag" />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import Tags from "@/views/tags/Tags";
import tagApi from "@/apis/tag";
import tagTypeDict from "./tagTypeDict";

export default {
  metaInfo() {
    return {
      title: `${this.thePageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    Tags
  },
  async created() {
    this.fetchTags();
  },
  computed: {
    thePageTitle() {
      const pageTitle = this.$route.query.tagType
        ? tagTypeDict[this.$route.query.tagType]
        : "tags";
      return this.$t(`pageTitle.${pageTitle}`);
    },
    tagTypeDict() {
      return tagTypeDict;
    }
  },
  data() {
    return {
      tags: []
    };
  },
  methods: {
    async fetchTags() {
      let tagResponse = null;
      if (this.$route.query.tagType) {
        tagResponse = await tagApi.fetchTags(this.$route.query.tagType);
      } else {
        tagResponse = await tagApi.fetchTags();
      }
      this.tags = tagResponse.tags;
    },
    async deleteTag(tagId) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        await tagApi.deleteTag(tagId);
        this.$message.success(this.$t("message.delete_success"));
        this.fetchTags();
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.shortcut {
  margin-bottom: 8px;
}

.shortcut > a {
  margin-right: 8px;
}

::v-deep .el-radio-button {
  position: relative;
}

.tagLink::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

::v-deep .el-radio-button.is-active > .el-radio-button__inner > .tagLink {
  color: #fff !important;
}
</style>
