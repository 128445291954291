var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{attrs:{"data":_vm.tags}},[_c('el-table-column',{attrs:{"label":_vm.$t('tag.name.en'),"prop":"name.en"}}),_c('el-table-column',{attrs:{"label":_vm.$t('tag.name.tw'),"prop":"name.tw"}}),_c('el-table-column',{attrs:{"label":_vm.$t('tag.name.cn'),"prop":"name.cn"}}),_c('el-table-column',{attrs:{"label":_vm.$t('column.action')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.enableToAddUser(scope.row.type))?_c('router-link',{attrs:{"to":_vm.$route.query.tagType
            ? {
                name: 'tag',
                params: { tagId: scope.row.id },
                query: { tagType: _vm.$route.query.tagType }
              }
            : {
                name: 'tag',
                params: { tagId: scope.row.id }
              }}},[_c('i',{staticClass:"fas fa-user-plus action-icon"})]):_vm._e(),_c('router-link',{attrs:{"to":{
          name: 'editTag',
          params: { tagId: scope.row.id }
        }}},[_c('i',{staticClass:"fas fa-edit action-icon"})]),_c('span',{on:{"click":function($event){return _vm.$emit('deleteTag', scope.row.id)}}},[_c('i',{staticClass:"fas fa-trash warning-icon"})])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }